'use strict';

export default Object.assign(
  {
    APP_AUDIENCE: 'app.brandwatch.com',
    AUTH_STORE_BASE_URL: 'https://login.stage.brandwatch.net/store',
    AUTH_LOGOUT_URL:
      'https://oidc-proxy.apps.aws.stage.brandwatch.net/logout?clientId=my-brandwatch-stage-client&returnTo=https://my.stage.brandwatch.net',
    AUTH_SERVICE_BASE_URL: 'https://tokens.platform-stage.gcp0.bwcom.net',
    BW_API_AUDIENCE: 'api.brandwatch.com',
    BW_API_ROOT_URL: 'https://api.stage.brandwatch.net',
    DATA_VAULTS_API_BASE_URL:
      'https://queue-manager.platform-stage.gcp0.bwcom.net',
    DATA_VAULTS_SEEN_ITEMS_API_BASE_URL:
      'https://seen-items.platform-stage.gcp0.bwcom.net',
    SUPPORTED_PROVIDERS: 'google,microsoft,zendesk,linkedin,luqi',
    SCHEMAS_BASE_URL:
      'https://bw-data-vaults-controls-schemas.storage.googleapis.com',
    LD_CLIENT_SIDE_ID: '5fa17659c3425e08e1e45c7b',
    LOCAL_FILE_UPLOAD_BATCH_SIZE: 2,
    ROLLBAR_ACCESS_TOKEN: '8ec070e97de74db0b201ef11e95c63a6',
    MIXPANEL_TOKEN: '10e6cb84043ee53cf0d4758e4470d5f6',
    MIXPANEL_PROXY_URL: 'https://mixpanel-proxy.platform-stage.gcp0.bwcom.net',
    FB_APP_ID: '154490291275976',
    FB_TOKEN_SERVICE_URL:
      'https://facebook-tokens.analytics-stage.gcp0.bwcom.net',
    BW_APP_URL: 'https://app.stage.brandwatch.net',
    MY_BRANDWATCH_URL: 'https://my.platform-stage.gcp0.bwcom.net',
    OIDC_BRIDGE_URL: 'https://oidc-proxy.apps.aws.stage.brandwatch.net',
    OIDC_CLIENT_ID: 'data-vaults-stage-client',
    ENVIRONMENT: 'DEVELOPMENT',
    SENTRY_DSN:
      'https://1683d02d31794d9781fa83622a63cd9c@o98544.ingest.sentry.io/4505602115108864',
    FALCON_URL: 'https://staging.falcon.io',
    USE_TEMPORAL_REVIEWS_MOCKED_DATA: true,
    REVIEWS_BULK_UPLOAD_URLS_MAX_ROWS: 100,
    REVIEWS_CONTENT_SOURCE_ID: 'review',
    CONFIG_CAT_FLAGS_URL:
      'https://config-cat-proxy.suite.stage.brandwatch.net/api/content-source-settings/eval-all',
    CONFIG_CAT_PROXY_API_KEY: 'FedRHzAgNk2YoR8NCWV-Pig.D-b*Nu32p@xCPqdV',
    FRONTEND_IMPORTMAP_URL: 'https://frontend-importmap.apps.aws.stage.brandwatch.net/bootstrap.js',
  },
  window.env || {}
);
