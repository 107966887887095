import React from 'react';
import {ProgressInfinite as Progress} from '@brandwatch/axiom-components';
import './ProgressInfinite.css';

export default function ProgressInfinite() {
  return (
    <div className="progress-infinite" data-testid="progress-infinite">
      <Progress />
    </div>
  );
}
