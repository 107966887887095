import {useQuery} from 'react-query';
import {useState, useMemo} from 'react';

import queryWrap from '../../../../../utils/queryWrap';
import {getReviewsUrls} from '../../../../../bwApi';
import {GET_REVIEWS_URLS} from '../../../../../queryKeys';

export enum URL_STATUS {
  READY = 'ready',
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
  FAILED = 'failed'
}

export type URL = {
  id: string;
  url: string;
  rating: number;
  numReviews: number;
  lastReviewed: Date;
  lastModified: Date;
  nextScrape: Date;
  status: URL_STATUS;
  projectId: number;
};

export type APIResponse = {
  urls: URL[];
  totalPages: number;
  totalItems: number;
};

const queryConfig = {
  retry: 1,
  retryDelay: 500,
  staleTime: 5 * 60 * 1000
};

const _isUrlDone = (url: URL) =>
  url?.status === URL_STATUS.COMPLETE || url?.status === URL_STATUS.FAILED;

export const _allUrlsDone = (data: APIResponse | undefined) => {
  if (!data?.urls?.length) {
    return false;
  }

  return Boolean(data.urls.every(_isUrlDone));
};

export default function useGetReviewsUrls(
  filterString: string,
  page: number,
  initialRefetchInterval: number
) {
  const [refetchInterval, setRefetchInterval] = useState<number>(
    initialRefetchInterval
  );

  const _queryConfig = useMemo(
    () => ({
      ...queryConfig,
      refetchInterval,
      onSuccess: (data: APIResponse) => {
        if (_allUrlsDone(data)) {
          setRefetchInterval(0);
        }
      }
    }),
    [refetchInterval]
  );

  return useQuery(
    [GET_REVIEWS_URLS, filterString, page],
    queryWrap(getReviewsUrls) as () => Promise<APIResponse>,
    _queryConfig
  );
}
