export const AUTH_PROVIDERS = 'AUTH_PROVIDERS';
export const CLIENT = 'CLIENT';
export const CONNECTORS = 'CONNECTORS';
export const CONNECTOR_CONFIGURATIONS = 'CONNECTOR_CONFIGURATIONS';
export const CONNECTOR_METADATA = 'CONNECTOR_METADATA';
export const CONNECTOR_SCHEMA = 'CONNECTOR_SCHEMA';
export const CONTENT_SOURCES_LIST = 'CONTENT_SOURCES_LIST';
export const ME = 'ME';
export const USER = 'USER';
export const PROJECTS_SUMMARY = 'PROJECTS_SUMMARY';
export const GET_PROJECTS_BY_IDS = 'GET_PROJECTS_BY_IDS';
export const FACEBOOK_TERMS_LIST = 'FACEBOOK_TERMS_LIST';
export const FACEBOOK_INVITE_LINK = 'FACEBOOK_INVITE_LINK';
export const FACEBOOK_PAGES_SUMMARY = 'FACEBOOK_PAGES_SUMMARY';
export const FACEBOOK_SAVE_PAGES = 'FACEBOOK_SAVE_PAGES';
export const CHECK_FACEBOOK_ACCESS = 'CHECK_FACEBOOK_ACCESS';
export const INSTAGRAM_HASHTAG_TERMS_LIST = 'INSTAGRAM_HASHTAG_TERMS_LIST';
export const INSTAGRAM_ACCOUNT_TERMS_LIST = 'INSTAGRAM_ACCOUNT_TERMS_LIST';
export const INSTAGRAM_AUTHENTICATION_TERMS_LIST =
  'INSTAGRAM_AUTHENTICATION_TERMS_LIST';
export const INSTAGRAM_AUTHENTICATION_USERS_LIST =
  'INSTAGRAM_AUTHENTICATION_USERS_LIST';
export const INSTAGRAM_AUTHENTICATED_ACCOUNTS =
  'INSTAGRAM_AUTHENTICATED_ACCOUNTS';
export const FACEBOOK_INSTAGRAM_OWNED_DATA = 'FB_INSTAGRAM_OWNED_DATA';
export const CHECK_FOR_INSTAGRAM_HASHTAG_ACCESS =
  'CHECK_INSTAGRAM_HASHTAG_ACCESS';
export const CHECK_FOR_INSTAGRAM_NOT_OWNED_ACCESS =
  'CHECK_INSTAGRAM_NOT_OWNED_ACCESS';
export const FACEBOOK_NOT_OWNED_AUTH_RATIO = 'FACEBOOK_NOT_OWNED_AUTH_RATIO';
export const INSTAGRAM_ACCOUNT_COUNT = 'INSTAGRAM_ACCOUNT_COUNT';
export const FACEBOOK_PAGE_COUNTS = 'FACEBOOK_PAGE_COUNTS';
export const OFF_PLATFORM_HASHTAG_COUNT = 'OFF_PLATFORM_HASHTAG_COUNT';
export const GET_AD_ACCOUNTS = 'GET_AD_ACCOUNTS';
export const GET_TRACKED_AD_ACCOUNTS = 'GET_TRACKED_AD_ACCOUNTS';
export const GET_REVIEWS_DOMAINS = 'GET_REVIEWS_DOMAINS';
export const GET_REVIEWS_URLS = 'GET_REVIEWS_URLS';
export const GET_REVIEWS_CLIENT_QUOTA = 'GET_REVIEWS_CLIENT_QUOTA';
export const GET_CONNECTED_CHANNELS = 'GET_CONNECTED_CHANNELS';
export const GET_CHANNELS_TO_CONNECT = 'GET_CHANNELS_TO_CONNECT';
