import OIDCSPATokenStore from '@brandwatch/oidc-spa-client';
import config from '../config';

let tokenStore;
export const getTokenStore = () => {
  if (tokenStore) return tokenStore;

  tokenStore = new OIDCSPATokenStore({
    OIDC_BRIDGE_URL: config.OIDC_BRIDGE_URL,
    OIDC_CLIENT_ID: config.OIDC_CLIENT_ID
  });

  return tokenStore;
};

export const getAccessToken = () => getTokenStore().getAccessToken();

export const become = (subject, subjectType) => getTokenStore().become(subject, subjectType);

export const hasBecomeUser = () => getTokenStore().hasBecomeUser();

export const getUser = async () => {
  const {
    given_name: firstName,
    family_name: lastName,
    ...rest
  } = await getTokenStore().getProfile();
  return {firstName, lastName, ...rest};
};

export const logout = () => getTokenStore().logout();


