import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .use({
    type: 'backend',
    read(language, namespace, callback) {
      /* webpackInclude: /translation\.json$/ */
      /* webpackChunkName: locales */
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      import(`./locales/${language}/${namespace}.json`)
        .then(response => {
          callback(null, response.default)
        })
        .catch(callback);
    }
  })
  .init({
    fallbackLng: 'en-GB',
    load: 'currentOnly',
    // eslint-disable-next-line no-undef
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react apparently
    }
  });


export default i18n;
