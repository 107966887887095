import * as Sentry from '@sentry/browser';
import config from './config';

export function startSentry(userId: string) {
  const environment = config.ENVIRONMENT;

  Sentry.init({
    enabled: environment === 'PRODUCTION',
    dsn: config.SENTRY_DSN,
    sampleRate: 1,
    environment: environment
  });

  Sentry.setUser({id: userId});
}
