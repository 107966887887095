import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ProgressInfinite from './components/ProgressInfinite.jsx';
import config from './config.js';
declare global {
  interface Window {
    importFrontendModule: <T>(moduleName: string) => Promise<T>;
  }
}

export const FlagsContext = React.createContext({});

const FLAGS_UPDATED_MESSAGE_TYPE = '[sdk-flags]:flagsUpdated';

type Props = {
  children: React.ReactNode;
  client: {userId: number; clientId: number; parentClientId: number};
};

export const FlagsProvider = ({children, client}: Props) => {
  interface FlagsModule {
    variation: (name: string, defaultValue: boolean) => boolean;
    waitForFlagsInitialization: () => Promise<void>;
    setup: (opts: {
      flagsUrl: string;
      user: {Identifier: number};
      apiKey: string;
    }) => void;
  }

  const [flagsModule, setFlagsModule] = useState<FlagsModule | null>(null);
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    async function importFlagsModule() {
      const flagsModule = await window.importFrontendModule('sdk-flags');
      await (flagsModule as FlagsModule).waitForFlagsInitialization();

      setFlagsModule(flagsModule as FlagsModule);
      setHasInitialized(true);
    }
    importFlagsModule().catch(error => {
      console.error('[sdk-flags] Error importing flags module:', error);
    });
  }, []);

  // Helper state to track changes of flags and force a re-render.
  const [hasChanged, setHasChanged] = useState<Date | undefined>(undefined);

  useEffect(() => {
    function handleFlagUpdates(event: {data: {type: string}; origin: string}) {
      if (
        event.data?.type === FLAGS_UPDATED_MESSAGE_TYPE &&
        event.origin === location.origin
      ) {
        // force a re-render of the context provider.
        setHasChanged(new Date());
      }
    }
    // Listening on "flagsUpdated" events
    window.addEventListener('message', handleFlagUpdates, false);

    return () => window.removeEventListener('message', handleFlagUpdates);
  }, []);

  const setup = useCallback(
    async (profile: {id: number; clientId: number; parentClientId: number}) => {
      if (!profile) {
        throw new Error(
          'User profile not provided during flags module initialization.'
        );
      }
      const flagsUser = {
        Identifier: profile.id,
        clientId: profile.clientId,
        parentClientId: profile.parentClientId
      };
      const setupOpts = {
        flagsUrl: config.CONFIG_CAT_FLAGS_URL,
        user: flagsUser,
        apiKey: config.CONFIG_CAT_PROXY_API_KEY
      };
      const fModule = await window.importFrontendModule('sdk-flags');
      (fModule as FlagsModule).setup(setupOpts);
    },
    []
  );

  useEffect(() => {
    if (client && !hasInitialized) {
      setup({
        id: client.userId,
        clientId: client.clientId,
        parentClientId: client.parentClientId
      });
    }
  }, [client, hasInitialized, setup]);

  // reference of variation changes on "flagsUpdated" events, forcing a re-render
  const variation = useCallback(
    (name: string, defaultValue: boolean) => {
      if (!hasInitialized) {
        throw new Error(
          '[FlagsProvider]: variation() called before flags module has initialized!'
        );
      }
      return flagsModule?.variation(name, defaultValue) ?? defaultValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flagsModule, hasInitialized, hasChanged]
  );

  const value = useMemo(
    () => ({
      setup,
      variation,
      hasInitialized
    }),
    [setup, variation, hasInitialized]
  );

  if (!hasInitialized) {
    return <ProgressInfinite />;
  }

  return (
    <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>
  );
};
