import {URL_STATUS} from '../components/reviews/hooks/api/urls/useGetReviewsUrls';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 2);

export const FAKE_DOMAIN_RESPONSE = {
  data: {
    domains: [
      {
        id: '1',
        domain: 'domain1',
        exampleUrl: 'example.com'
      },
      {
        id: '2',
        domain: 'domain2',
        exampleUrl: 'example2.com'
      }
    ],
    totalPages: 2
  }
};

export const FAKE_URL_RESPONSE = {
  data: {
    urls: [
      {
        id: '1',
        url: 'https://example1.com',
        rating: 4.0,
        numReviews: 150,
        lastReviewed: today,
        lastModified: today,
        nextScrape: tomorrow,
        status: 'complete',
        projectId: 163051992
      },
      {
        id: '2',
        url: 'https://example2.com',
        rating: 3.0,
        numReviews: 50,
        lastReviewed: today,
        lastModified: today,
        nextScrape: tomorrow,
        status: 'ready',
        projectId: 162898200
      }
    ],
    page: 1,
    totalPages: 2,
    totalItems: 4
  }
};

export const FAKE_URL_RESPONSE_PAGE_2 = {
  data: {
    urls: [
      {
        id: '3',
        url: 'https://example3.com',
        rating: 4.0,
        numReviews: 70,
        lastReviewed: today,
        lastModified: today,
        nextScrape: tomorrow,
        status: 'complete',
        projectId: 162898200
      },
      {
        id: '4',
        url: 'https://example4.com',
        rating: 3.0,
        numReviews: 80,
        lastReviewed: today,
        lastModified: today,
        nextScrape: tomorrow,
        status: 'ready',
        projectId: 163292265
      }
    ],
    page: 2,
    totalPages: 2,
    totalItems: 4
  }
};

let _fakeUrlResponseAttempts = 0;
export const FAKE_URL_ATTEMPTS_UNTIL_DONE = 2;
export const resetFakeUrlResponseAttempts = () =>
  (_fakeUrlResponseAttempts = 0);

const fakeStatus = status => {
  if (status === URL_STATUS.COMPLETE) {
    return status;
  }

  return URL_STATUS.FAILED;
};

export const getFakeUrlResponse = (page = 1) => {
  const {data} = page === 1 ? FAKE_URL_RESPONSE : FAKE_URL_RESPONSE_PAGE_2;

  if (++_fakeUrlResponseAttempts > FAKE_URL_ATTEMPTS_UNTIL_DONE) {
    //all not 'complete' -> 'failed'
    return {
      data: {
        ...data,
        urls: data.urls.map(url => ({
          ...url,
          status: fakeStatus(url.status)
        }))
      }
    };
  }

  return {data};
};

export const FAKE_CLIENT_URL_QUOTA = {
  data: {
    client: 1,
    maxUrls: 100,
    numberUrls: 50
  }
};

export const FAKE_CREATED_URL = {
  data: {
    urls: [
      {
        id: '5',
        url: 'https://example5.com',
        rating: 4.0,
        numReviews: 150,
        lastReviewed: today,
        lastModified: today,
        nextScrape: tomorrow,
        status: 'ready'
      }
    ]
  }
};

export const FAKE_REMOVED_URL = {
  data: {
    urls: [
      {
        id: '5'
      }
    ]
  }
};

export const getFakedValidatedUrls = urls => {
  const validatedUrls = urls.map(({url, index}) => ({
    url: `${url}/validated`,
    error: '',
    index
  }));

  return {
    data: {
      urls: validatedUrls
    }
  };
};

export const FAKE_URL_SUGGESTED_QUERY = {
  data: {
    suggestedQuery:
      'url: ("amazon.com/dp/1449311652" OR "amazon.com/dp/B0763S9QGJ" OR "amazon.com/dp/B08MX7NHBB" OR "amazon.com/dp/B0C8BJL1D3" OR "amazon.com/dp/B0C9DR6M4H" OR "amazon.com/dp/B0B625QG84" OR "amazon.com/dp/3985610215")'
  }
};
